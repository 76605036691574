<template>
  <main>

    <div class="container">
      <b-jumbotron id="jumbotron" border-variant="dark" header-level="5">
        <template #header>{{$t('message.sft_terms')}}</template>
        <template #lead>
          {{$t('message.sft_terms_sec')}}
        </template>
      </b-jumbotron>
    </div>

    <!-- Page Content -->
    <div class="container pt-2" id="termsContainer">
      <p class="leads">
        {{$t('message.sft_terms_read')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_further')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_continuation')}} <span class="font-weight-bolder">/safetravel.today/</span>, {{$t('message.sft_terms_contmeans')}}
      </p>

      <h4 class="headerunderline">{{$t('message.sft_terms_general')}}</h4>

      <p class="leads">
        {{$t('message.sft_terms_platform')}}
        <u>SafeTravel.Today</u> 
        {{$t('message.sft_terms_platform_based')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_goal')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_visitor')}}
        <span class="font-weight-bolder">{{$t('message.sft_terms_ppolicy')}}</span> {{$t('message.sft_terms_ppolicy_p')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_reserves')}}
      </p>


      <h4 class="headerunderline">{{$t('message.sft_terms_intellect')}}</h4>
      <p class="leads">
        {{$t('message.sft_terms_trademark')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_allcontent')}} <span class="font-weight-bolder">/safetravel.today/</span>{{$t('message.sft_terms_legalregist')}}

      <p class="leads">
        {{$t('message.sft_terms_anycoping')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_udert')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_extremely_per')}}
      </p>

      <h4 class="headerunderline">{{$t('message.sft_terms_infoprod')}}</h4>

      <p class="leads">
        {{$t('message.sft_terms_commited_ac')}}
      </p>

      <h4 class="headerunderline">{{$t('message.sft_terms_resvisitor')}}</h4>

      <p class="leads">
        <b>4.1.</b> {{$t('message.sft_terms_vistuser')}}
      </p>

      <p class="leads">
        <u>{{$t('message.sft_terms_indicatively')}}</u>
      </p>

      <ul class="leads">
        <li>{{$t('message.sft_terms_indicativelylo')}}</li>
        <li>{{$t('message.sft_terms_indicativelylt')}}</li>
        <li>{{$t('message.sft_terms_indicativelylthr')}}</li>
      </ul>


      <p class="leads">
        <b>4.2.</b> {{$t('message.sft_terms_furassum')}}
      </p>

      <p class="leads">
        <b>4.3.</b> {{$t('message.sft_terms_theeuser')}}
      </p>


      <p class="leads">
        <b>4.4.</b> {{$t('message.sft_terms_theeuseres')}}
      </p>

      <p class="leads">
        <b>4.5.</b> <u>{{$t('message.sft_terms_theeuseer')}}</u>:
      </p>

      <p class="leads">
        {{$t('message.sft_terms_bearsthe')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_obligprevent')}}
      </p>

      <p class="leads">
        <b>4.6.</b> {{$t('message.sft_terms_userfullres')}}
      </p>

      <p class="leads">
        <b>4.7.</b> {{$t('message.sft_terms_userhastheres')}}
      </p>

      <h4 class="headerunderline">{{$t('message.sft_terms_limitliab')}}</h4>

      <p class="leads">
        {{$t('message.sft_terms_comptranst')}}
      </p>

      <p class="leads">
        <b>5.1.</b> {{$t('message.sft_terms_ccompensate')}}
      </p>

      <p class="leads">
        <b>5.2.</b> {{$t('message.sft_terms_doesnotguar')}}
      </p>

      <p class="leads">
        <b>5.3.</b> {{$t('message.sft_terms_osprovides')}} <u>{{$t('message.sft_terms_osjustitis')}}</u>. {{$t('message.sft_terms_nocaseshall')}}
      </p>

      <p class="leads">
        <b>5.4.</b> {{$t('message.sft_terms_tcnotliable')}}
      </p>

      <p class="leads">
        <b>5.5.</b> {{$t('message.sft_terms_thprovisions')}}
      </p>

      <p class="leads">
        <b>5.6.</b> {{$t('message.sft_terms_incorrectduring')}}
      </p>

      <p class="leads">
        <b>5.7.</b> {{$t('message.sft_terms_notresdamage')}}
      </p>

      <p class="leads">
        <b>5.8.</b> {{$t('message.sft_terms_cnnotliab')}}
      </p>

      <p class="leads">
        <b>5.9.</b> {{$t('message.sft_terms_waaarrabt')}}
      </p>

      <h4 class="headerunderline">{{$t('message.sft_terms_orders')}}</h4>

      <p class="leads">
        {{$t('message.sft_terms_orderspara')}}
      </p>

      <h4 class="headerunderline">{{$t('message.sft_terms_priceelist')}}</h4>

      <p class="leads">
        {{$t('message.sft_terms_priceelisttext')}}
      </p>

      <h4 class="headerunderline">{{$t('message.sft_terms_meethospayment')}}</h4>

      <p class="leads">
        {{$t('message.sft_terms_pppoptions')}}
      </p>

      <ul class="leads">
        <li>{{$t('message.sft_terms_pppoptionsa')}}</li>
        <li>{{$t('message.sft_terms_pppoptionsb')}}</li>
        <li>{{$t('message.sft_terms_pppoptionsc')}}</li>
      </ul>

      <p class="leads">
        {{$t('message.sft_terms_pppoptionscaseab')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_shiiipingproc')}}
      </p>

      <h4 class="headerunderline">{{$t('message.sft_terms_secoftr')}}</h4>

      <p class="leads">
        {{$t('message.sft_terms_secoftrtxt')}} <span class="font-weight-bolder">/safetravel.today/</span>.
      </p>

      <h4 class="headerunderline">{{$t('message.sft_terms_prrtitle')}}</h4>

      <p class="leads">
        {{$t('message.sft_terms_prrtitlesuv')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_prrtitlesuvtxt')}}
      </p>

      <h4 class="headerunderline">{{$t('message.sft_terms_withrettitle')}}</h4>

      <p class="leads">
        <b>11.1</b> {{$t('message.sft_terms_withrettext')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_withrettexta')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_withrettextb')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_withrettexttext')}} 
      </p>

      <h4 class="headerunderline">{{$t('message.sft_terms_applicablelawtitle')}}</h4>

      <p class="leads">
        {{$t('message.sft_terms_applicablelawtxt')}} <u>{{$t('message.sft_terms_applicablelawtxtunder')}}</u>
      </p>

      <p class="leads">
        {{$t('message.sft_terms_whhentheweb')}} <span class="font-weight-bolder">/safetravel.today/</span> {{$t('message.sft_terms_whhenthewebexup')}}
      </p>

      <p class="leads">
        {{$t('message.sft_terms_formoreinfo')}} <a class="linkterms" href="mailto:info@safetravel.today">info@safetravel.today</a>
      </p>

    </div>
  </main>
</template>

<script>
import {
  BRow, BCol,BJumbotron 
} from 'bootstrap-vue'
export default {
  components: {
    BRow,
    BCol,
    BJumbotron 
  },
  data() {
    return {
    }
  },
}
</script>